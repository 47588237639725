<template>
  <div>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              style="min-width: 250px"
              class="text-left"
              v-for="header in itemHeaders"
              v-bind:key="header.text"
            >{{header.text}}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in saleItemsData" :key="index">
            <td><v-btn icon><v-icon>mdi-eye</v-icon></v-btn>{{ item.ItemCode }}</td>
            <td>{{ item.ItemName }}</td>
            <td>{{ item.CodeBars }}</td>
            <td>{{ item.SerialNum }}</td>
            <td>{{ item.Quantity  }}</td>
            <td>{{ item.DelivrdQty }}</td>
            <td>{{ item.UseBaseUn }}</td>
            <td>{{ item.PackQty }}</td>
            <td>{{ item.PriceBefDi }}</td>
            <td>{{ item.DiscPrcnt }}</td>
            <td>{{ item.Rate }}</td>
            <td>{{ item.Price }}</td>
            <td>{{ item.TaxCode }}</td>
            <td>{{ item.PriceAfVAT }}</td>
            <td>{{ item.LineTotal }}</td>
            <td>{{ item.WhsCode }}</td>
            <td>{{ item.ShipDate }}</td>
            <td>{{ item.SlpCode }}</td>
            <td>{{ item.Commission }}</td>
            <td>{{ item.Quantity }}</td> 
            <td>{{ item.OnHand }}</td>
            <td>{{ item.IsCommited }}</td>
            <td>{{ item.OnOrder }}</td>
            <td>{{ item.TreeType }}</td>
            <td>{{ item.LineStatus }}</td>
            <td>{{ item.TargetType }}</td>
            <td>{{ item.TargetEntry }}</td>
            <td>{{ item.BaseType }}</td>
            <td>{{ item.BaseRef }}</td>
            <td>{{ item.BaseEntry }}</td>
            <td>{{ item.BaseLine }}</td>
            <td>{{ item.SpecPrice }}</td>
            <td>{{ item.VatSum }}</td>
            <td>{{ item.GrssProfit }}</td>
            <td>{{ item.PoTrgNum }}</td>
            <td>{{ item.BackOrdr }}</td>
            <td>{{ item.FreeTxt }}</td>
            <td>{{ item.PickStatus }}</td>
            <td>{{ item.UomCode }}</td>
            <td>{{ item.unitMsr }}</td>
            <td>{{ item.NumPerMsr }}</td>
            <td>{{ item.Text }}</td>
            <td>{{ item.GTotal }}</td>
            <td>{{ item.Quantity }}</td>
            <td>{{ item.Quantity }}</td>
            <td>{{ item.UgpCode }}</td>
            <td>{{ item.AgrNo }}</td>
            <td>{{ item.LinePoPrss }}</td>
            <td>{{ item.OnHand }}</td>
            <td>{{ item.IsCommited }}</td>
            <td>{{ item.OnOrder }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- end of data table -->
  </div>
</template>

<script>
import { itemHeaders } from "../_data/index.js";

export default {
  props: {
    saleItemsData: {
      type: Array
    }
  },
  data() {
    return {
      itemHeaders: itemHeaders
    };
  }
};
</script>

<style scoped>
</style>