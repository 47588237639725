export const itemHeaders = [
    { text: "Item Code.", value: "id" },
    { text: "Item Description", value: "country.name" },
    { text: "Bar Code", value: "" },
    { text: "Serial No", value: "" },
    { text: "Quantity", value: "" },
    { text: "Delivered Qty", value: "" },
    { text: "Inventory UoM", value: "" },
    { text: "No of Packages", value: "" },
    { text: "Unit Price", value: "" },
    { text: "Discount %", value: "" },
    { text: "Rate", value: "" },
    { text: "Price After Discount", value: "" },
    { text: "Tax Code", value: "" },
    { text: "Gross Price After Disc", value: "" },
    { text: "Total (LC)", value: "" },
    { text: "Warehouse Code", value: "" },
    { text: "Del. Date", value: "" },
    { text: "Sales Employee", value: "" },
    { text: "Comm %", value: "" },
    { text: "Open Qty", value: "" },
    { text: "In Stock", value: "" },
    { text: "Commited", value: "" },
    { text: "Ordered", value: "" },
    { text: "Bom Type", value: "" },
    { text: "Row Status", value: "" },
    { text: "Target Type", value: "" },
    { text: "Target Key", value: "" },
    { text: "Base Type", value: "" },
    { text: "Base Ref", value: "" },
    { text: "Base Key", value: "" },
    { text: "Base Row", value: "" },
    { text: "Price Source", value: "" },
    { text: "Tax Amount(LC)", value: "" },
    { text: "Gross Profit(LC)", value: "" },
    { text: "Procurement Doc", value: "" },
    { text: "Partial Delivery", value: "" },
    { text: "Free Text", value: "" },
    { text: "Pick Status", value: "" },
    { text: "UoM Code", value: "" },
    { text: "UoM Name", value: "" },
    { text: "Items Per Unit", value: "" },
    { text: "Item Details", value: "" },
    { text: "Gross Total(LC)", value: "" },
    { text: "Qty(Inventory UoM)", value: "" },
    { text: "Open Inv Qty", value: "" },
    { text: "UoM Group", value: "" },
    { text: "Blanket Agreement No", value: "" },
    { text: "Allow Procmnt. Doc.", value: "" },
    { text: "Qty in Whse", value: "" },
    { text: "Committed Qty in Whse", value: "" },
    { text: "Ordered Qty in Whse", value: "" }
];