<template>
  <default-layout>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>Create A/R Downpayment Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text to="/trading/ar-downpayment-invoice">
            <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- editor -->
            <editor :currencies="currencies" :initial="dataItem" @data="save"></editor>
          <!-- end -->
        </v-card-text>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import Editor from '../_components/editor.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(['user'])
  },
  data: () => ({
    dataItem: {},
    currencies: []
  }),
  methods: {
    save(data) {
      const url = "/ar_invoice";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, 'r');
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/ar-invoice");
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then(res => {
          self.currencies = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getCurrencies();
  }
};
</script>